// src/data/virtualToursData.js

import Cover1 from '../assets/images/cover_tours/01-cover.jpeg';
import Cover2 from '../assets/images/cover_tours/02-cover.jpeg';
import Cover4 from '../assets/images/cover_tours/04-cover.jpeg';
import Cover5 from '../assets/images/cover_tours/05-cover.jpeg';
import Cover6 from '../assets/images/cover_tours/06-cover.jpeg';
import Cover7 from '../assets/images/cover_tours/07-cover.jpg';
import Cover8 from '../assets/images/cover_tours/08-cover.jpg';
import Cover9 from '../assets/images/cover_tours/09-cover.jpg';
import Cover10 from '../assets/images/cover_tours/10-cover.jpg';
import Cover11 from '../assets/images/cover_tours/11-cover.jpg';
import Cover12 from '../assets/images/cover_tours/12-cover.jpg';

const virtualToursData = [
  {
    id: 1,
    title: 'Virtuelna tura 1',
    image: Cover1,
    url: 'https://kuula.co/share/collection/7KLZc?logo=0&info=0&fs=1&vr=1&sd=1&gyro=0&initload=0&thumbs=1',
  },
  {
    id: 2,
    title: 'Virtuelna tura 2',
    image: Cover2,
    url: 'https://kuula.co/share/collection/7cZKL?logo=0&info=0&fs=1&vr=1&sd=1&gyro=0&initload=0&thumbs=1',
  },
  {
    id: 4,
    title: 'Virtuelna tura 4',
    image: Cover4,
    url: 'https://kuula.co/share/5zK7T/collection/7KC1D?logo=0&info=0&fs=1&vr=1&sd=1&gyro=0&initload=0&thumbs=1',
  },
  {
    id: 5,
    title: 'Virtuelna tura 5',
    image: Cover5,
    url: 'https://kuula.co/share/collection/7Zh7Q?logo=0&info=0&fs=1&vr=1&sd=1&gyro=0&initload=0&thumbs=1',
  },
  {
    id: 6,
    title: 'Virtuelna tura 6',
    image: Cover6,
    url: 'https://kuula.co/share/5Q9Qk/collection/7KwHY?logo=0&info=0&fs=1&vr=1&sd=1&gyro=0&initload=0&thumbs=1',
  },
  {
    id: 7,
    title: 'Virtuelna tura 7',
    image: Cover7,
    url: 'https://twinmotion.unrealengine.com/panorama/_0WUJVfUNEk10Pl3?lang=en',
  },
  {
    id: 8,
    title: 'Virtuelna tura 8',
    image: Cover8,
    url: 'https://twinmotion.unrealengine.com/panorama/Ubeeg6_G7mPRcnet?lang=en',
  },
  {
    id: 9,
    title: 'Virtuelna tura 9',
    image: Cover9,
    url: 'https://twinmotion.unrealengine.com/panorama/8c_AAgCxK7Rlttsa?lang=en&c=A9F103FC410DA3E01935CE854075B26E',
  },
  {
    id: 10,
    title: 'Virtuelna tura 10',
    image: Cover10,
    url: 'https://twinmotion.unrealengine.com/panorama/aCcvr0SEY_Cv76zg?lang=en',
  },
  {
    id: 11,
    title: 'Virtuelna tura 11',
    image: Cover11,
    url: 'https://twinmotion.unrealengine.com/panorama/47jHFhcD-fxKU_za?lang=en',
  },
  {
    id: 12,
    title: 'Virtuelna tura 12',
    image: Cover12,
    url: 'https://twinmotion.unrealengine.com/panorama/zusp1N6vG--nh804?lang=en',
  },
];

export default virtualToursData;
