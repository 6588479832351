import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import tours from '../data/virtualToursData'; // Uvozimo default
import 'swiper/css';
import 'swiper/css/navigation';
import { GiClick } from 'react-icons/gi';
import { FaPlayCircle } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const VirtuelneTure = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [currentTourUrl, setCurrentTourUrl] = useState('');
  const swiperRef = useRef(null);
  const { t } = useTranslation();

  const openPopup = (tourUrl) => {
    setCurrentTourUrl(tourUrl);
    setShowPopup(true);
    document.body.style.overflow = 'hidden';
  };

  const closePopup = () => {
    setShowPopup(false);
    setCurrentTourUrl('');
    document.body.style.overflow = 'auto';
  };

  const handleContextMenu = (e) => {
    e.preventDefault(); // Sprečava desni klik
  };

  const handlePopupBackgroundClick = (e) => {
    if (e.target.classList.contains('popup-background')) {
      closePopup();
    }
  };

  return (
    <div id='virtuelneture' className="relative w-full h-screen">
      {/* Main Slider */}
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        navigation
        speed={2000}
        modules={[Navigation, Autoplay]}
        className="w-full h-full"
      >
        {tours.map((tour) => (
          <SwiperSlide key={tour.id} className="relative">
            <img
              src={tour.image}
              alt={tour.title}
              className="w-full h-full object-cover select-none"
              onContextMenu={handleContextMenu}
              draggable="false"
            />
            <div className="absolute top-4 left-4 text-white">
              <h1
                className="text-3xl font-bold"
                style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}
              >
                {t('projekti.virtuelne_ture.virtuelna_tura_1')}
              </h1>
            </div>
            <div className='absolute inset-0 flex items-center justify-center'>
              <button
                onClick={() => openPopup(tour.url)}
                className="text-white hover:text-blue-600 duration-300"
              >
                <FaPlayCircle size={60} />
              </button>
            </div>
            <div className="absolute bottom-4 right-4">
              <button
                onClick={() => openPopup(tour.url)}
                className="mt-2 text-[#696969] underline bg-white rounded-xl py-2 px-4 flex items-center"
              >
                {t('projekti.virtuelne_ture.virtuelna_tura_2')} <GiClick className='ml-2' />
              </button>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Popup Modal */}
      {showPopup && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-10 popup-background"
          onClick={handlePopupBackgroundClick}
        >
          <div className="relative bg-transparent w-11/12 max-w-6xl">
            <button
              className="absolute top-[-50px] right-[0px] text-3xl text-gray-500"
              onClick={closePopup}
            >
              &times;
            </button>
            <iframe
              src={currentTourUrl}
              width="100%"
              height="580px"
              className="rounded-lg"
              allowFullScreen
              title="Virtuelna Tura"
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default VirtuelneTure;
