import RH6 from '../assets/images/remeta_house/RH6.jpg';
import RH7 from '../assets/images/remeta_house/RH7.png';
import RH8 from '../assets/images/remeta_house/RH8.png';
import RH9 from '../assets/images/remeta_house/RH9.png';
import RH10 from '../assets/images/remeta_house/RH10.jpg';
import RH11 from '../assets/images/remeta_house/RH11.png';
import RH12 from '../assets/images/remeta_house/RH12.png';

import PH11 from '../assets/images/popovica_house/PH1.1.png'
import PH12 from '../assets/images/popovica_house/PH1.2.png'
import PH13 from '../assets/images/popovica_house/PH1.3.png'
import PH14 from '../assets/images/popovica_house/PH1.4.png'
import PH15 from '../assets/images/popovica_house/PH1.5.png'

import AF1 from '../assets/images/a_frame/A4.png'

import PHS1 from '../assets/images/penthouse_subotica/PHS1.jpg'
import PHS2 from '../assets/images/penthouse_subotica/PHS2.jpg'
import PHS3 from '../assets/images/penthouse_subotica/PHS3.jpg'
import PHS4 from '../assets/images/penthouse_subotica/PHS4.png'
import PHS5 from '../assets/images/penthouse_subotica/PHS5.png'
import PHS6 from '../assets/images/penthouse_subotica/PHS6.jpg'
import PHS7 from '../assets/images/penthouse_subotica/PHS7.png'

import SK1 from '../assets/images/sk_house/SK1.jpeg';
import SK2 from '../assets/images/sk_house/SK2.jpeg';
import SK3 from '../assets/images/sk_house/SK3.jpeg';
import SK4 from '../assets/images/sk_house/SK4.jpeg';
import SK5 from '../assets/images/sk_house/SK5.jpeg';
import SK6 from '../assets/images/sk_house/SK6.jpeg';
import SK7 from '../assets/images/sk_house/SK7.jpeg';
import SK8 from '../assets/images/sk_house/SK8.jpeg';
import SK9 from '../assets/images/sk_house/SK9.jpeg';
import SK10 from '../assets/images/sk_house/SK10.jpeg';
import SK11 from '../assets/images/sk_house/SK11.jpeg';
import SK12 from '../assets/images/sk_house/SK12.jpeg';
import SK13 from '../assets/images/sk_house/SK13.jpeg';
import SK14 from '../assets/images/sk_house/SK14.jpeg';
import SK15 from '../assets/images/sk_house/SK15.jpeg';
import SK16 from '../assets/images/sk_house/SK16.jpeg';

import SU1 from '../assets/images/su_house/SU1.jpeg';
import SU2 from '../assets/images/su_house/SU2.jpeg';
import SU3 from '../assets/images/su_house/SU3.jpeg';
import SU4 from '../assets/images/su_house/SU4.jpeg';
import SU5 from '../assets/images/su_house/SU5.jpeg';
import SU6 from '../assets/images/su_house/SU6.jpeg';
import SU7 from '../assets/images/su_house/SU7.jpeg';
import SU8 from '../assets/images/su_house/SU8.jpeg';
import SU9 from '../assets/images/su_house/SU9.jpeg';
import SU10 from '../assets/images/su_house/SU10.jpeg';
import SU11 from '../assets/images/su_house/SU11.jpeg';
import SU12 from '../assets/images/su_house/SU12.jpeg';
import SU13 from '../assets/images/su_house/SU13.jpeg';
import SU14 from '../assets/images/su_house/SU14.jpeg';

import TY1 from '../assets/images/typhooun_hill/TY1.jpeg';
import TY2 from '../assets/images/typhooun_hill/TY2.jpeg';
import TY3 from '../assets/images/typhooun_hill/TY3.jpeg';

import TYH1 from '../assets/images/typhooun_hill2/TYH1.jpeg';
import TY2H from '../assets/images/typhooun_hill2/TYH2.jpeg';
import TYH3 from '../assets/images/typhooun_hill2/TYH3.jpeg';
import TYH4 from '../assets/images/typhooun_hill2/TYH4.jpeg';
import TYH5 from '../assets/images/typhooun_hill2/TYH5.jpeg';




export const projects = [
  {
    id: 2,
    name: 'Penthouse Subotica',
    description: 'Opis projekta 2',
    mainImage: PHS4,
    moreImages: [PHS1, PHS2, PHS3, PHS4, PHS5, PHS6, PHS7],
  },
  
  {
    id: 1,
    name: 'Remeta House',
    description: 'Opis projekta 1',
    mainImage: RH7,
    moreImages: [RH6, RH7, RH8, RH9, RH10, RH11, RH12],
  },

  // Novi projekti umetnuti između prva dva
  {
    id: 3,
    name: 'SK House',
    description: 'Opis projekta 3',
    mainImage: SK1,
    moreImages: [SK1, SK2, SK3, SK4, SK5, SK6, SK7, SK8, SK9, SK10, SK11, SK12, SK13, SK14, SK15, SK16],
  },
  {
    id: 4,
    name: 'SU House',
    description: 'Opis projekta 4',
    mainImage: SU1,
    moreImages: [SU1, SU2, SU3, SU4, SU5, SU6, SU7, SU8, SU9, SU10, SU11, SU12, SU13, SU14],
  },
  {
    id: 5,
    name: 'Typhoon Hill',
    description: 'Opis projekta 5',
    mainImage: TY1,
    moreImages: [TY1, TY2, TY3],
  },
  {
    id: 6,
    name: 'Typhoon Hill 2',
    description: 'Opis projekta 6',
    mainImage: TYH1,
    moreImages: [TYH1, TY2H, TYH3, TYH4, TYH5],
  },
  // Zadržavanje poslednja dva projekta na kraju
  {
    id: 7,
    name: 'A Frame',
    description: 'Opis projekta 7',
    mainImage: AF1,
    moreImages: [AF1],
  },
  {
    id: 8,
    name: 'Popovica House',
    description: 'Opis projekta 8',
    mainImage: PH11,
    moreImages: [PH11, PH12, PH13, PH14, PH15],
  },
];

  