import React from 'react';

const ProtectedImage = ({ src, alt, className }) => {
  const handleContextMenu = (e) => {
    e.preventDefault(); // Sprečava desni klik
  };

  return (
    <div className={`relative flex justify-center items-center`}>
      <img
        src={src}
        alt={alt}
        className={className + " select-none"} // Prevent text selection
        onContextMenu={handleContextMenu} // Prevent right-click
        draggable="false" // Prevent drag-and-drop
      />
      {/* Ovde možeš dodati vodeni žig ili neki overlay */}
    </div>
  );
};

export default ProtectedImage;
