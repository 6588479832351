// src/i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Učitaj prevode
import translationSR from './locales/sr/translation.json';
import translationEN from './locales/en/translation.json';

i18n
  .use(initReactI18next) // koristi i18next sa Reactom
  .init({
    resources: {
      sr: {
        translation: translationSR,
      },
      en: {
        translation: translationEN,
      },
    },
    lng: 'sr', // podrazumevani jezik
    fallbackLng: 'sr', // ako prevod ne postoji, koristi srpski
    interpolation: {
      escapeValue: false, // React već escapuje
    },
  });

export default i18n;
