import React from 'react'
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import logo from '../assets/images/logo.png';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();
  return (
    <div className='bg-white'>
        <div className='grid md:grid-cols-3 max-w-[1240px] mx-auto gap-7 py-10'>
        <div className='flex flex-col space-y-4 mx-auto items-center'>
            <img src={logo} className='w-8/12 md:w-4/12' alt="" />
            <p className='hidden md:block' >©2022 by Ðurđev Design.</p>
        </div>
        <div className='flex flex-col space-y-4 mx-auto'>
            <p>{t('footer.kontakt_tel')}</p>
            <a href="tel:+381691419977" className='flex flex-row items-center'><FaPhone size={16} className='mr-1' /> +381691419977</a>
            <a href="tel:+38163557199" className='flex flex-row items-center'><FaPhone size={16} className='mr-1' /> +38163557199</a>
            
        </div>
        <div className='flex flex-col space-y-4 mx-auto items-center'>
            <a href="mailto:design.djurdjev@gmail.com" className='flex flex-row items-center'><IoIosMail size={20} className='mr-1' /> design.djurdjev@gmail.com</a>
            <div className='flex flex-row space-x-4'>
                <a href=""><FaFacebookSquare size={20} /></a>
                <a href="https://www.instagram.com/dizajn.enterijera_rs" target="_blank" rel="noopener noreferrer">
                    <FaInstagramSquare size={20} />
                </a>
                <a href=""><FaLinkedin size={20}/></a>
            </div>
            
        </div>
        <div className='block md:hidden mx-auto'>
            <p>©2022 by Ðurđev Design.</p>
        </div>

        </div>
    </div>
  )
}

export default Footer