import Eksterijer from "./components/Eksterijer";
import EksterijerHeading from "./components/EksterijerHeading";
import Enterijer from "./components/Enterijer";
import EnterijerHeading from "./components/EnterijerHeading";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Kontakt from "./components/Kontakt";
import Navbar from "./components/Navbar";
import O_Nama from "./components/O_Nama";
import VirtuelneTure from "./components/VirtuelneTure";
import VirtuelneTureHeading from "./components/VirtuelneTureHeading";
import Vizuelizacija from "./components/Vizuelizacija";

export default function App() {
  return (
    <>
        <Navbar />
        <Hero />
        <EnterijerHeading />
        <Enterijer />
        <EksterijerHeading />
        <Eksterijer />
        <VirtuelneTureHeading />
        <VirtuelneTure />
        <O_Nama />
        <Kontakt />
        <Footer />
    </>
  )
}