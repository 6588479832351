import React from 'react'
import { useTranslation } from 'react-i18next';

const VirtuelneTureHeading = () => {
  const { t } = useTranslation();
  return (
    <div className='h-20 md:h-32 bg-[#4f4f4f]  flex items-center ml-4'>
        <h1 className='text-3xl md:text-5xl text-black tracking-widest text-center'>
          {t('nav.virtuelne_ture')}
        </h1>
    </div>
  )
}

export default VirtuelneTureHeading