import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import '../i18n';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState('sr'); // Dodajemo state za praćenje jezika


  const handleNav = () => {
    setNav(!nav);
    if (!nav) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  };

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng); // Ažuriramo activeLanguage kada promenimo jezik
  };

  return (
    <div>
      <div className='max-w-[1240px] relative z-50'>
        <ul className='hidden md:flex justify-end space-x-4 pt-4 pb-2 text-lg'>
          <li><a href="#onama" className='p-4 hover:text-black text-white transition duration-300'>{t('nav.usluge')}</a></li>
          <li><a href="#kontakt" className='p-4 hover:text-black text-white transition duration-300'>{t('nav.kontakt')}</a></li>
          <li>
            <button
              className={`pr-2 border-r-2 ${activeLanguage === 'sr' ? 'text-white' : 'text-black'}`}
              onClick={() => changeLanguage('sr')}
            >
              Sr
            </button>
            <button
              className={`pl-2 ${activeLanguage === 'en' ? 'text-white' : 'text-black'}`}
              onClick={() => changeLanguage('en')}
            >
              En
            </button>
          </li>
        </ul>

        <div onClick={handleNav} className='block md:hidden absolute top-0 right-0 p-4 z-50 bg-[#4f4f4f] rounded-lg'>
          {nav ? <AiOutlineClose size={25} /> : <AiOutlineMenu size={25} />}
        </div>

        <ul className={nav ? 'fixed left-0 top-0 w-full h-full bg-[#4f4f4f] ease-in-out duration-1000 flex flex-col justify-center items-center space-y-10 z-40' 
            : 'ease-in-out duration-800 fixed top-[-100%]'}>
          <li onClick={handleNav}><a href="#enterijer" className='p-4'>{t('nav.enterijer_tel')}</a></li>
          <li onClick={handleNav}><a href="#eksterijer" className='p-4'>{t('nav.eksterijer_tel')}</a></li>
          <li onClick={handleNav}><a href="#virtuelneture" className='p-4'>{t('nav.virtuelne_ture_tel')}</a></li>  
          <li onClick={handleNav}><a href="#onama" className='p-4'>{t('nav.usluge')}</a></li>
          <li onClick={handleNav}><a href="#kontakt" className='p-4'>{t('nav.kontakt')}</a></li>
          <li>
            <button
              className={`pr-2 border-r-2 ${activeLanguage === 'sr' ? 'text-white' : 'text-black'}`}
              onClick={() => changeLanguage('sr')}
            >
              Sr
            </button>
            <button
              className={`pl-2 ${activeLanguage === 'en' ? 'text-white' : 'text-black'}`}
              onClick={() => changeLanguage('en')}
            >
              En
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
