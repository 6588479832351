import React from 'react'
import { useTranslation } from 'react-i18next';

const EksterijerHeading = () => {
  const { t } = useTranslation();
  return (
    <div className='h-20 md:h-32 bg-[#4f4f4f]  flex items-center ml-4'>
        <h1 className='text-3xl md:text-5xl text-black tracking-widest'>
          {t('nav.eksterijer')}
        </h1>
    </div>
  )
}

export default EksterijerHeading