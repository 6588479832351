import React from 'react';
import logo from '../assets/images/logo.png';
import hero from '../assets/images/penthouse_subotica/PHS7.png';
import enterijerImg from '../assets/images/remeta_house/RH6.jpg';
import eksterijerImg from '../assets/images/remeta_house/RH4.png';
import virtuelneTureImg from '../assets/images/cover_tours/01-cover.jpeg';
import ProtectedImage from '../data/ProtectedImage';
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t } = useTranslation();

  return (
    <div className='relative w-full h-screen md:h-[94vh] bg-cover bg-center flex flex-col items-center justify-center' style={{ backgroundImage: `url(${hero})` }}>
      {/* Logo centriran u sredini */}
      <ProtectedImage className='w-11/12 lg:w-8/12 h-auto md:bg-transparent rounded-lg ' src={logo} alt="Logo" />

      {/* Linkovi pozicionirani pri dnu */}
      <div className='absolute bottom-10 w-full grid grid-cols-3 gap-1'>
        
        {/* Link za Enterijer */}
        <a 
          href="#enterijer" 
          className='relative group hidden md:flex justify-center items-center text-lg md:text-3xl tracking-widest bg-[#4f4f4f]'
        >
          <span className="relative text-center z-10 md:group-hover:translate-y-[-215px] md:group-hover:bg-[#4f4f4f] w-full p-1 transition duration-1000 text-black" >
            {t('nav.enterijer')} {/* Prevod sa react-i18next */}
          </span>
          <div 
            className="absolute bottom-[-20px] left-0 w-full h-0 bg-cover bg-center opacity-0 
            group-hover:opacity-100 group-hover:h-64 transition-all duration-1000 transform group-hover:translate-y-[-20px] hidden md:block"
            style={{ backgroundImage: `url(${enterijerImg})` }}
          ></div>
        </a>

        {/* Link za Eksterijer */}
        <a 
          href="#eksterijer" 
          className='relative group hidden md:flex  justify-center items-center text-lg md:text-3xl tracking-widest bg-[#4f4f4f]'
        >
          <span className="relative text-center z-10 md:group-hover:translate-y-[-215px] md:group-hover:bg-[#4f4f4f] w-full p-1 transition duration-1000 text-black">
            {t('nav.eksterijer')} {/* Prevod sa react-i18next */}
          </span>
          <div 
            className="absolute bottom-[-20px] left-0 w-full h-0 bg-cover bg-center opacity-0 
            group-hover:opacity-100 group-hover:h-64 transition-all duration-1000 transform group-hover:translate-y-[-20px] hidden md:block"
            style={{ backgroundImage: `url(${eksterijerImg})` }}
          ></div>
        </a>

        {/* Link za Virtuelne Ture */}
        <a 
          href="#virtuelneture" 
          className='relative group hidden md:flex  justify-center items-center text-lg md:text-3xl tracking-widest bg-[#4f4f4f]'
        >
          <span className="relative text-center z-10 md:group-hover:translate-y-[-215px] md:group-hover:bg-[#4f4f4f] w-full p-1 transition duration-1000 text-black" >
            {t('nav.virtuelne_ture')} {/* Prevod sa react-i18next */}
          </span>
          <div 
            className="absolute bottom-[-20px] left-0 w-full h-0 bg-cover bg-center opacity-0 
            group-hover:opacity-100 group-hover:h-64 transition-all duration-1000 transform group-hover:translate-y-[-20px] hidden md:block"
            style={{ backgroundImage: `url(${virtuelneTureImg})` }}
          ></div>
        </a>
      </div>
    </div>
  );
}

export default Hero;
