import React, { useRef } from 'react';
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';

const Kontakt = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_vzhr92a', 'template_i7tzqhb', form.current, 'I4U_NFK3tH7bEPZzt')
      .then((result) => {
          console.log(result.text);
          alert('Poruka je uspešno poslata!');
      }, (error) => {
          console.log(error.text);
          alert('Došlo je do greške, pokušajte ponovo.');
      });

      e.target.reset(); // Resetuje formu nakon slanja
  };

  const { t } = useTranslation();

  return (
    <div id='kontakt' className='grid md:grid-cols-2 max-w-[1240px] mx-auto py-32 '>
      <div className='flex flex-col space-y-4 mx-auto my-10 items'>
        <h1 className='text-white text-3xl'>{t('kontakt.nasov')}</h1>
        <a href="mailto:design.djurdjev@gmail.com" className='flex flex-row items-center'><IoIosMail size={20} className='mr-1' />design.djurdjev@gmail.com</a>
        <a href="tel:+381691419977" className='flex flex-row items-center'> <FaPhone size={16} className='mr-1' />+381691419977</a>
        <a href="tel:+38163557199" className='flex flex-row items-center'> <FaPhone size={16} className='mr-1' />+38163557199</a>
        <div className='flex flex-row space-x-4'>
          <a href=""><FaFacebookSquare size={20} /></a>
          <a href="https://www.instagram.com/dizajn.enterijera_rs" target="_blank" rel="noopener noreferrer">
            <FaInstagramSquare size={20} />
          </a>
          <a href=""><FaLinkedin size={20}/></a>
        </div>
      </div>

      <div>
        <form ref={form} onSubmit={sendEmail} className="max-w-xl mx-auto p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">{t('kontakt.ime')}</label>
              <input type="text" name="user_name" className="mt-1 block w-full p-2 border border-gray-300 rounded-md" placeholder={t('kontakt.ime_placeholder')} />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input type="email" name="user_email" className="mt-1 block w-full p-2 border border-gray-300 rounded-md" placeholder={t('kontakt.email_placeholder')} />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">{t('kontakt.telefon')}</label>
              <input type="tel" name="user_phone" className="mt-1 block w-full p-2 border border-gray-300 rounded-md" placeholder={t('kontakt.telefon_placeholder')} />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">{t('kontakt.adresa')}</label>
              <input type="text" name="user_address" className="mt-1 block w-full p-2 border border-gray-300 rounded-md" placeholder={t('kontakt.adresa_placeholder')} />
            </div>
          </div>

          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">{t('kontakt.poruka')}</label>
            <textarea name="message" className="mt-1 block w-full p-2 border border-gray-300 rounded-md" rows="4" placeholder={t('kontakt.poruka_placeholder')} />
          </div>

          <div className="mt-4">
            <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600">
               {t('kontakt.posalji')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Kontakt;
