import RH1 from '../assets/images/remeta_house/RH1.png';
import RH2 from '../assets/images/remeta_house/RH2.png';
import RH3 from '../assets/images/remeta_house/RH3.png';
import RH4 from '../assets/images/remeta_house/RH4.png';
import RH5 from '../assets/images/remeta_house/RH5.png';

import BH1 from '../assets/images/beocin_house/BH1.png'
import BH2 from '../assets/images/beocin_house/BH2.png'

import AF2 from '../assets/images/a_frame/A1.png'
import AF3 from '../assets/images/a_frame/A2.png'
import AF4 from '../assets/images/a_frame/A3.png'

import PH1 from '../assets/images/palanka_house/1.png'
import PH2 from '../assets/images/palanka_house/2.png'

import L1 from '../assets/images/lider/L1.png'
import L2 from '../assets/images/lider/L2.jpg'

import KH1 from '../assets/images/kac_house/KH1.jpeg'
import KH2 from '../assets/images/kac_house/KH2.jpeg'

import EB1 from '../assets/images/erker_building/EB1.jpeg'
import EB2 from '../assets/images/erker_building/EB2.jpeg'
import EB3 from '../assets/images/erker_building/EB3.jpeg'
import EB4 from '../assets/images/erker_building/EB4.jpeg'
import EB5 from '../assets/images/erker_building/EB5.jpeg'
import EB6 from '../assets/images/erker_building/EB6.jpeg'
import EB7 from '../assets/images/erker_building/EB7.jpeg'
import EB8 from '../assets/images/erker_building/EB8.jpeg'
import EB9 from '../assets/images/erker_building/EB9.jpeg'
import EB10 from '../assets/images/erker_building/EB10.jpeg'

export const projects = [
    {
      id: 1,
      name: 'Remeta House',
      description: 'Opis projekta 1',
      mainImage: RH5,
      moreImages: [RH1, RH2, RH3, RH4, RH5],
    },
    {
      id: 2,
      name: 'Beočin House',
      description: 'Opis projekta 2',
      mainImage: BH1,
      moreImages: [BH1, BH2],
    },
    {
      id: 6,
      name: 'Kać House',
      description: 'Opis projekta 6',
      mainImage: KH1,
      moreImages: [KH1, KH2],
    },
    {
      id: 7,
      name: 'Erker Building',
      description: 'Opis projekta 7',
      mainImage: EB1,
      moreImages: [EB1, EB2, EB3, EB4, EB5, EB6, EB7, EB8, EB9, EB10],
    },
    {
        id: 3,
        name: 'A Frame',
        description: 'Opis projekta 2',
        mainImage: AF2,
        moreImages: [AF2, AF3, AF4],
    },
    {
        id: 4,
        name: 'Palanka House',
        description: 'Opis projekta 2',
        mainImage: PH1,
        moreImages: [PH1, PH2],
    },
    {
        id: 5,
        name: 'Lider',
        description: 'Opis projekta 2',
        mainImage: L1,
        moreImages: [L1, L2],
    },
  ];
  