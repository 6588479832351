import React from 'react'
import { BsBadge3dFill } from "react-icons/bs";
import { HiOutlineMap } from 'react-icons/hi';
import { WiDaySunny } from 'react-icons/wi';
import { MdOutlineEngineering } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

const O_Nama = () => {

    const { t } = useTranslation();
  return (
    <div className='bg-white'>
        <div id='onama' className='max-w-[1240px] mx-auto text-center pt-10 md:py-14'>
            <h1 className='text-4xl'>{t('usluge.naslov')}</h1>
        </div>
        <div className='max-w-[1240px] m-auto grid md:grid-cols-2 gap-8 py-14'>

            <div className='flex flex-col justify-center items-center space-y-4 mx-3 p-10 rounded-xl shadow-lg md:hover:scale-110 transition duration-500'>
                <BsBadge3dFill size={40} />
                <h1 className='text-2xl md:text-3xl text-center'>{t('usluge.3d_vizualizacija.naziv')}</h1>
                <p className='text-sm'>{t('usluge.3d_vizualizacija.opis')}</p>
            </div>

            <div className='flex flex-col justify-center items-center space-y-4 mx-3 p-10 rounded-xl shadow-lg md:hover:scale-110 transition duration-500'>
                <HiOutlineMap size={40} />
                <h1 className='text-2xl md:text-3xl text-center'>{t('usluge.prostor_plani.naziv')}</h1>
                <p className='text-sm'>{t('usluge.prostor_plani.opis')}</p>
            </div>

            <div className='flex flex-col justify-center items-center space-y-4 mx-3 p-10 rounded-xl shadow-lg md:hover:scale-110 transition duration-500'>
                <WiDaySunny size={40}/>
                <h1 className='text-2xl md:text-3xl text-center'>{t('usluge.osvetljenje.naziv')}</h1>
                <p className='text-sm'>{t('usluge.osvetljenje.opis')}</p>
            </div>

            <div className='flex flex-col justify-center items-center space-y-4 mx-3 p-10 rounded-xl shadow-lg md:hover:scale-110 transition duration-500'>
                <MdOutlineEngineering size={40}/>
                <h1 className='text-2xl md:text-3xl text-center'>{t('usluge.konsultacije.naziv')}</h1>
                <p className='text-sm'>{t('usluge.konsultacije.opis')}</p>
            </div>


        </div>
    </div>
  )
}

export default O_Nama