import { useRef, useState } from 'react';
import { projects } from '../data/enterijer_projects';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { GiClick } from 'react-icons/gi';
import { useTranslation } from 'react-i18next';

const Enterijer = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const { t } = useTranslation();

  const openPopup = (project) => {
    setSelectedProject(project);
    setShowPopup(true);
    document.body.style.overflow = 'hidden';
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedProject(null);
    document.body.style.overflow = 'auto';
  };

  const handleContextMenu = (e) => {
    e.preventDefault(); // Sprečava desni klik
  };

  const handlePopupBackgroundClick = (e) => {
    if (e.target.classList.contains('popup-background')) {
      closePopup();
    }
  };

  return (
    <div id='enterijer' className="relative w-full h-screen">
      {/* Main Slider */}
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        navigation
        speed={2000}
        touchRatio={1} // Standardna brzina prevlačenja
        touchStartPreventDefault={false} // Omogućava da prevlačenje bude ugodno
        modules={[Navigation, Autoplay]}
        className="w-full h-full"
      >
        {projects.map((project) => (
          <SwiperSlide key={project.id} className="relative">
            <img
              src={project.mainImage}
              alt={project.name}
              className="w-full h-full object-cover select-none cursor-pointer"
              onContextMenu={handleContextMenu}
              draggable="false"
              onClick={() => openPopup(project)}
            />
            <div className="absolute top-4 left-4 text-white">
              <p
                className="text-lg font-bold mb-4"
                style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}
              >
                {t('nav.projekti.enterijer')}
              </p>
              <h1
                className="text-3xl font-bold"
                style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}
              >
                {project.name}
              </h1>
            </div>
            <div className="absolute bottom-4 right-4">
              <button
                onClick={() => openPopup(project)}
                className="mt-2 text-[#696969] underline bg-white rounded-xl py-2 px-4 flex items-center"
              >
                {t('nav.vidite_vise')} <GiClick className="ml-2" />
              </button>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Popup Modal */}
      {showPopup && selectedProject && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-10 popup-background"
          onClick={handlePopupBackgroundClick}
        >
          <div className="relative bg-transparent w-11/12 max-w-6xl">
            <button
              className="absolute top-[-50px] right-[0px] md:top-[-30px] md:right-[-40px] text-3xl text-gray-500"
              onClick={closePopup}
            >
              &times;
            </button>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              navigation
              speed={2000}
              touchRatio={1} // Standardna brzina prevlačenja
              touchStartPreventDefault={false} // Omogućava da prevlačenje bude ugodno
              modules={[Navigation]}
            >
              {selectedProject.moreImages.map((image, index) => (
                <SwiperSlide key={index}>
                  <div className="flex items-center justify-center w-full h-full">
                    <img
                      src={image}
                      alt={`More ${selectedProject.name}`}
                      className="h-[50vh] md:h-[90vh] w-full object-cover select-none"
                    />
                  </div>

                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}
    </div>
  );
};

export default Enterijer;
